.sales-manager-parent-row {
  margin-top: 20px;
  box-shadow: 1px 1px 4px 1px rgba(56, 56, 56, 0.4) !important;
  background-color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 100%;
  .heading-col {
    border-bottom: 1px solid #eb078e;
    padding: 10px 25px 15px 12px;
    .heading-span {
      line-height: 17px;
      letter-spacing: 0px;
      color: rgba(24, 42, 88, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      text-transform: uppercase;
    }
    .sync-col {
      display: flex;
      align-items: center;
      line-height: 12px;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      img,
      .ant-spin {
        padding-left: 10px;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .client-btn {
    height: 28px;
    width: 128px;
    padding: 2px 15px;
    text-transform: uppercase;
  }
  .selected {
    opacity: 1;
  }
  .sales-rep-btn {
    margin-left: 25px;
    height: 28px;
    width: 128px;
    padding: 2px 15px;
    text-transform: uppercase;
  }
  .graph-section {
    min-height: 50vh;
    padding: 15px 20px;
    .spinner-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .sales-by-client-div {
      display: flex;
      align-items: center;
      gap: 25px;
      .sort-icons {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .graph-text-span {
      font-size: 15px;
      color: #eb078e;
      font-weight: bold;
    }
    .graph-label-with-text {
      display: flex;
      gap: 20px;
      .actual-div,
      .target-div {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #231f20;
        font-size: 13px;
        white-space: nowrap;
        .blue-dot {
          height: 13px;
          width: 13px;
          background-color: #29aae1;
          border-radius: 50%;
          display: inline-block;
        }
        .red-dot {
          height: 13px;
          width: 13px;
          background-color: #eb078e;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
  }
}

@primary-color: #eb078e;