.btnSubmit-cls:hover {
  cursor: pointer;
  color: white !important;
  background-color: #ec7346 !important;
}

.model-logo-new-specs {
  width: 100%;
  text-align: center;
  padding: 20px;
  background: white;
  margin: auto;
}

.forgot-password-parent-div {
  border: 1px solid #29aae1 !important;
  box-shadow: #29aae1 0px 0px 3px !important;

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: #383838;
  }

  .ant-form-item-label > label {
    color: #383838;
  }

  .reset-form {
    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
      padding: 0px;
    }

    .ant-form-item-label > label {
      height: 32px;
      line-height: 13px;
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
    }

    .ant-input {
      width: 100% !important;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 6px;
      color: #383838;
      font-size: 14px;
      font-family: sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .login-text {
    color: #29aae1 !important;
    margin-bottom: 30px !important;
    p {
      margin-bottom: 0.3em;
    }
    .reset-span {
      padding: 0px 20px;
      line-height: 13px;
      letter-spacing: 0px;
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      text-align: justify;
      span {
        font-size: 11px !important;
      }
    }
    .span-div {
      padding: 0px 45px;
      span {
        padding-top: 0px !important;
      }
    }
  }
  .reset-text-div {
    margin-bottom: 15px !important;
  }
  .ant-form-item-explain {
    padding-left: 8px !important;
  }

  .send-btn {
    line-height: 17px;
    background-color: #29aae1;
    border: 1px solid #29aae1;
    border-radius: 2px;
    width: 125px;
    height: 28px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Open Sans", Arial;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    margin-top: 25px;
  }
  .bottoom-p-text {
    color: rgba(56, 56, 56, 1);
    font-family: "Open Sans", Arial;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
  }
}

@primary-color: #eb078e;