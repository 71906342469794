.login-most-parent-div {
  width: 100vw;
  height: 100vh;
  .parent-row {
    width: 100%;
    height: 100%;
    background-color: #d1d1d1;
  }

  .login-form-parent-div {
    background-color: white;
    width: 324px;
    height: 428px;
    text-align: center;
    border: 1px solid rgba(235, 7, 142, 1);
    box-shadow: rgb(235, 7, 142) 0px 0px 3px;
    border-radius: 3px;
    .logo-div {
      height: 64px;
      width: 258px;
      margin-top: 30px;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .login-text {
      color: rgba(235, 7, 142, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 40px;
      span {
        font-size: 12px;
        color: rgb(56, 56, 56);
        font-weight: 500;
        padding-top: 10px;
      }
    }
    .ant-input {
      width: 204px;
    }
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: none;
      box-shadow: none;
      border-right-width: 0px;
      outline: 0;
    }
    .email-input,
    .ant-input-affix-wrapper {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .ant-input-affix-wrapper,
    .ant-input-status-success,
    .email-input {
      border: none;
      border-bottom: 1px solid #d9d9d9;
    }
    .forgot-email-input {
      border: 1px solid #d9d9d9 !important;
    }
    .ant-form-item-explain {
      font-size: 11px;
    }
    .ant-form-item-explain-error {
      text-align: start;
    }
    .forgot-password-div {
      text-align: right;
      margin: 0px 0px 30px 0px;
      .link-text {
        text-decoration: underline;
        font-size: 10px;
        color: #717171;
        font-weight: normal;
      }
    }
    .login-btn {
      line-height: 17px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(235, 7, 142, 1);
      border-radius: 2px;
      width: 125px;
      height: 28px;
      color: rgba(235, 7, 142, 1);
      text-transform: uppercase;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

@primary-color: #eb078e;