.production-manager-parent-row {
  margin-top: 20px;
  box-shadow: 1px 1px 4px 1px rgba(56, 56, 56, 0.4) !important;
  background-color: rgb(255, 255, 255);
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 100%;
  .heading-col {
    border-bottom: 1px solid #eb078e;
    padding: 10px 25px 15px 12px;
    .heading-span {
      line-height: 17px;
      letter-spacing: 0px;
      color: rgba(24, 42, 88, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      text-transform: uppercase;
    }
    .sync-col {
      display: flex;
      align-items: center;
      line-height: 12px;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      img,
      .ant-spin {
        padding-left: 10px;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .client-btn {
    height: 28px;
    width: 128px;
    padding: 2px 15px;
    text-transform: uppercase;
  }
  .selected {
    opacity: 1;
  }
  .sales-rep-btn {
    margin-left: 25px;
    height: 28px;
    width: 128px;
    padding: 2px 15px;
    text-transform: uppercase;
  }
  .graph-section {
    min-height: 50vh;
    padding: 15px 20px;
    .spinner-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .production-by-client-div {
      display: flex;
      align-items: center;
      gap: 25px;
      .sort-icons {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .graph-text-span {
      font-size: 15px;
      color: #eb078e;
      font-weight: bold;
    }
    .graph-label-with-text {
      display: flex;
      gap: 10px;
      .actual-div,
      .target-div {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #231f20;
        font-size: 13px;
        .blue-dot,
        .red-dot,
        .small-format-dot,
        .large-format-dot,
        .ais-dot,
        .emails-dot,
        .proportional-dot {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
        }
        .blue-dot {
          background-color: #29aae1;
        }
        .small-format-dot {
          background-color: #690277;
        }
        .large-format-dot {
          background-color: #188bf6;
        }
        .ais-dot {
          background-color: #a1d1ff;
        }
        .proportional-dot {
          background-color: #7450ba;
        }
        .red-dot {
          background-color: #eb078e;
        }
        .emails-dot {
          background-color: #a0a6d8;
        }
        .dot {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
    .ordered-compl-label-with-text {
      display: flex;
      gap: 10px;
      .ordered-div,
      .completed-div {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #231f20;
        font-size: 13px;
        .ordered-dot,
        .completed-dot {
          height: 13px;
          width: 13px;
          border-radius: 50%;
          display: inline-block;
        }
        .ordered-dot {
          background-color: #29aae1;
        }
        .completed-dot {
          background-color: #eb078e;
        }
      }
    }
  }
}

.enter-pin-modal {
  .ant-modal-body label {
    padding-left: 0px;
  }
  input {
    width: 30px !important;
    height: 30px;
    margin-right: 25px !important;
  }
  .ant-modal-content {
    width: 351px;
    height: 468px;
    border: 2px solid rgba(41, 170, 225, 1);
    .ant-modal-close-icon svg {
      fill: rgba(41, 170, 225, 1);
    }
  }
  .ant-modal-header {
    border-bottom-color: white;
    padding: 12px 8px 6px 8px;
    margin: 0 16px;
    .ant-modal-title {
      // margin-top: 20px;
      text-align: center;
      // color: rgba(41, 170, 225, 1);
      font-family: 'Open Sans', Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      border-bottom: none;
      // text-transform: uppercase;
      .text-span {
        line-height: 19px;
        letter-spacing: 0px;
        color: rgba(35, 31, 32, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        text-align: center;
      }
      .name-span {
        line-height: 19px;
        letter-spacing: 0px;
        color: rgba(35, 31, 32, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-align: left;
      }
      .ant-avatar {
        margin-right: 10px;
      }
    }
  }
  .ant-modal-body {
    padding: 16px 24px 32px 24px;
    // .numbers-parent-col {
    margin: 15px 0px;
    .num-div-col,
    .pin-col {
      display: flex;
      justify-content: center;
      align-items: center;
      .pin-div {
        width: 48px;
        height: 48px;
        background-color: #fff;
        color: rgba(41, 170, 225, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgba(41, 170, 225, 1);
        span {
          line-height: 51px;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 1);
          font-family: 'Open Sans', Arial;
          font-weight: 700;
          font-style: normal;
          font-size: 42px;
          text-align: center;
        }
      }
    }
    .num-div {
      width: 60px;
      height: 60px;
      background-color: rgba(41, 170, 225, 1);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      span {
        line-height: 51px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        font-family: 'Open Sans', Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 42px;
        text-align: center;
      }
    }

    // }
    .input-fields-col {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .done-btn {
      height: 30px;
      width: 127px;
      background: rgba(41, 170, 225, 1);
      color: white;
      margin: 0 auto;
    }
  }
}

.add-operator-div {
  margin: 20px 0px 5px 20px;
  .add-operator-btn {
    color: #f72fa1;
    border-color: #f72fa1;
    width: 139px;
  }
}

.comment-list-date-div {
  padding-left: 15px; // 55px
  color: rgba(156, 154, 154, 1);
  font-family: 'Open Sans', Arial;
  font-weight: 400;
  font-size: 9px;
}

.prod-chat-popup {
  .comment-list {
    /* width */
    .ant-list-items::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .ant-list-items::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .ant-list-items::-webkit-scrollbar-thumb {
      background: rgba(157, 155, 155, 0.8);
    }

    /* Handle on hover */
    .ant-list-items::-webkit-scrollbar-thumb:hover {
      background: rgba(112, 110, 110, 0.5);
    }

    .ant-list-items {
      max-height: 400px;
      overflow-y: auto;
      padding-right: 15px;
      .ant-comment .ant-comment-inner {
        padding: 2px 0 16px 0;
        .ant-comment-avatar {
          // display: flex;
          // align-items: center;
          img {
            margin-top: 10px;
          }
        }
        .ant-comment-content {
          border-width: 0px solid rgba(209, 209, 209, 1);
          border-radius: 2px;
          padding: 3px 10px;
          .ant-comment-content-author {
            span {
              padding-right: 30px;
            }
            .ant-comment-content-author-name {
              color: #9c9a9a;
              font-weight: 500;
              font-size: 9px;
              width: 120px;
            }
            .ant-comment-content-author-time {
              color: #9c9a9a;
              font-weight: 500;
              font-size: 9px;
            }
          }
          .ant-comment-content-detail {
            color: rgba(115, 115, 115, 1);
            font-weight: 500;
            font-size: 13px;
          }
        }
      }

      .send {
        .ant-comment-inner {
          .ant-comment-content {
            background-color: rgba(216, 237, 243, 1);
          }
        }
      }

      .received {
        .ant-comment-inner {
          .ant-comment-content {
            background-color: rgba(235, 7, 142, 0.08);
          }
        }
      }
    }
  }
  .ant-popover-content {
    .ant-popover-title {
      padding: 12px 24px 4px 16px;
      .cross-icon-div {
        position: absolute;
        right: 10px;
        z-index: 999999;
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
  .ant-popover-inner-content {
    padding: 0px;
  }
  .ant-list-footer {
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      left: -1px;
      padding: 0;
      border: 1px solid #d9d9d9;
      border-left-width: 0;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #d9d9d9;
      border-right-color: #fff;
    }
    .ant-input-group-addon {
      border: 1px solid #d9d9d9;
      .ant-input-search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #fff;
        background: #fff;
        height: 30px;
      }
    }
  }
}

@primary-color: #eb078e;