@import "~antd/dist/antd.less";

.ant-tooltip {
  display: none;
}

.ant-select-dropdown {
  min-width: 180px !important;
}

.ant-select-item-option-content {
  white-space: pre-line;
}

.page-top-div {
  margin-top: 25px;
  .text-span {
    font-size: 16px;
    color: #eb078e;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0px;
  }
}

.special-table-header-text,
.ant-table-column-title {
  min-height: 25px; //40px
  font-family: "Open Sans", Arial;
  font-size: 11px;
  color: #231f20;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 6px;
  text-transform: uppercase;
}

.ant-table-column-title-without-input {
  min-height: 25px;
  font-family: "Open Sans", Arial;
  font-size: 11px;
  color: #231f20;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
  div {
    justify-content: center;
  }
}

.special-table .ant-table-column-sorters {
  margin-bottom: 0px; // 5px
}

.special-table-header-text-center {
  min-height: 25px; //40px
  font-family: "Open Sans", Arial;
  font-size: 11px;
  font-weight: 700;
  color: #231f20;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.activity-type-table {
  .ant-table-measure-row {
    display: none;
  }
  .ant-table-tbody > tr > td {
    padding: 16px 16px 16px 16px !important;
    input.ant-input {
      height: inherit;
    }
    .ant-picker-large {
      padding: 6.5px 11px 6.5px 0px;
    }
    .ant-input-lg {
      padding: 4px 11px 4px 0px;
    }
    textarea.ant-input {
      max-height: 46px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 35px;
      padding-left: 0px;
    }
    // .ant-input {
    //   text-align: left;
    // }
    .ant-picker:not(.ant-picker-range) .ant-picker-input input,
    input.ant-input,
    .ant-picker-large,
    .ant-input-lg,
    .ant-select-selection-item,
    textarea.ant-input {
      font-size: 13px;
      font-family: "Open Sans";
      color: rgb(35 31 32 / 1); // 50%
      padding-left: 0px !important;
      text-align: left;
    }
  }
}

.special-table .ant-table-tbody > tr > td {
  //   padding: 12px 16px 11px 16px;
  padding: 23px 16px 16px 16px;
}

// .product-manager-table .ant-table-tbody > tr > td {
//   padding: 7px 16px 6px 16px;
// }

.special-table .custom-expanded-table .ant-table-tbody > tr > td {
  padding: 5px 16px 4px 16px;
}

.special-table .custom-expanded-table .ant-table-thead > tr th {
  padding: 0px 2px;
}

.special-table .ant-table-tbody > tr > td {
  // border-bottom-color: #e2e2e2;
  font-size: 13px;
  color: #231f20;
  .custom-icon {
    fill: rgb(235 7 142 / 60%);
    width: 16px;
    height: 16px;
    font-size: 16px;
  }
  .disable-icon {
    cursor: not-allowed;
  }
}

.special-table .ant-table-tbody > tr > td .rotate-icon {
  transform-origin: 9px 30px;
  transform: rotate(320deg);
}

.special-table {
  cursor: pointer;
  //   padding-left: 8px;
  //   padding-bottom: 20px;
  padding: 0px 15px 20px;
  //   padding-bottom: 20px;
  //   min-height: 748px;
  border: 8px;
  .ant-table-column-sorter {
    padding-top: 25px; // 40px
  }

  .custom-categories-crud-table {
    border-radius: 8px !important;
  }
  .select-parent-div {
    width: 100%;
  }

  input.ant-input
  // .ant-select-selector
  {
    // border-radius: 8%;
    height: 23px;
  }
  .ant-table-thead > tr {
    th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
    th {
      border-bottom-color: #717171;
      padding: 12px 2px;
      background-color: #ffffff;
    }
    .top {
      width: 100%;
    }
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #ffffff;
  }

  // .ant-table-tbody > tr.editable-row:hover > td,
  // .ant-table-tbody > tr.editable-row > td,
  // .ant-table-tbody > tr.editable-row:hover > td.ant-table-cell-row-hover,
  // .ant-table-tbody > tr.editable-row > td.ant-table-cell-row-hover {
  //   background: #fff;
  //   color: #eb078e;
  //   .ant-table-row-expand-icon {
  //     // color: #eb078e;
  //   }
  //   .rotate-icon {
  //     fill: #231f20;
  //   }
  //   .custom-icon {
  //     fill: #eb078e;
  //   }
  //   .attach-parent-div {
  //     color: #231f20;
  //   }
  // }

  .ant-table-tbody > tr.ant-table-row:hover > .first-level-status-td {
    .ant-tag-has-color {
      background-color: rgb(148 212 240); // !important
    }
  }

  .ant-table-tbody > .ant-table-row > .ant-table-cell-row-hover {
    .status-tag {
      background-color: rgb(148 212 240) !important;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #eb078e;
    color: #fff;
    .ant-table-row-expand-icon {
      color: #eb078e;
    }
    .rotate-icon {
      fill: #fff;
    }
    svg {
      fill: #fff;
    }
    .attach-parent-div {
      color: white;
    }
    // .border-less-input,
    .border-less-input::placeholder {
      color: #fff !important;
    }
    // .status-tag {
    //   background-color: #fff !important;
    // }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
  }

  .ant-table-expanded-row {
    td {
      border-bottom: 1px solid #eb078e;
    }
    .table-second-level .ant-table-tbody > tr.ant-table-row:hover > td,
    .table-second-level .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #fff;
      color: #231f20;
      svg {
        fill: #eb078e;
      }
      .format-div svg {
        fill: #231f20;
      }
    }
    .custom-expanded-table .ant-table {
      margin: -16px -16px -16px 0px !important;
      .ant-table-column-title {
        flex: revert;
        .select-parent-div {
          width: 100%;
        }
      }
    }
    .ant-table-column-sorter {
      padding-top: 4px;
    }
    .table-second-level {
      .ant-table-tbody {
        tr .first-level-status-td {
          padding: 16px 0px 16px 12px;
        }
        .format-div {
          color: #231f20;
          span {
            color: #eb078e;
          }
        }
        td {
          border: 1px solid transparent;
          border-bottom-color: lightgray;
          padding: 16px 16px 16px 16px;
          .table-third-level {
            border-bottom: 1px solid #eb078e;
          }
        }
      }
      .ant-table-expanded-row {
        td {
          border-bottom: 1px solid #eb078e;
        }
      }
      .table-third-level {
        .ant-table-tbody {
          td {
            // border: 1px solid transparent;
            border-bottom: 1px solid lightgray;
            padding: 16px 16px 16px 16px;
          }
        }
        .ant-table-expanded-row {
          td {
            border-bottom: 1px solid #eb078e;
          }
        }
      }
      .select-align-center {
        .ant-select-selection-overflow {
          justify-content: center;
          .ant-select-selection-item {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
    .without-search-header {
      .ant-table-thead th.ant-table-column-has-sorters {
        .ant-table-column-sorters {
          display: inline-flex;
          .ant-table-column-sorter-full {
            padding-top: 1px;
          }
        }
      }
    }
  }

  .expand-parent td + svg {
    background-color: #eb078e;
    color: #fff !important;
    border-bottom: none;
  }
  .ant-table-expanded-row-level-1 > td {
    border: 1px solid #eb078e;
  }

  .ant-table-expanded-row > td {
    // border-bottom: 1px solid #eb078e !important;
  }

  .fourth-level-expanded-row {
    .ant-table-expanded-row > td {
      border-bottom: 1px solid transparent;
    }
  }

  .quatity-form-input {
    margin-bottom: 0px;
    input,
    .ant-input-lg {
      font-size: 13px;
      height: 32px;
    }
    .ant-form-item-control {
      .ant-form-item-explain {
        display: none;
      }
    }
  }

  table > tbody > tr.expand-parent {
    background-color: #eb078e;
    color: #fff;
    td {
      background-color: #eb078e;
      color: #fff;
      .custom-icon,
      .rotate-icon {
        fill: #fff;
      }
      .format-div {
        color: #fff !important;
        svg {
          fill: #fff !important;
        }
      }
      // .status-tag {
      //   background-color: #fff !important;
      // }
    }
    .first-level-status-td {
      // background-color: white !important;
    }
    .first-level-status-td {
      // padding: 16px 0px 16px 12px;
      .ant-tag-has-color {
        background-color: rgb(148 212 240); // !important
      }
    }
  }

  table > tbody > tr.expand-parent:hover {
    background-color: #eb078e !important;
    color: #fff !important;
    td {
      background-color: #eb078e !important;
      color: #fff !important;
      .custom-icon,
      .rotate-icon {
        fill: #fff !important;
      }
      .format-div {
        color: #fff !important;
        svg {
          fill: #fff !important;
        }
      }
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
}

.custom-btn1,
.custom-btn1:focus,
.custom-btn1:hover,
.custom-btn1:active {
  background-color: #eb078e;
  span {
    color: #fff;
    font-size: 13px;
  }
  opacity: 0.4;
}
.ant-select-dropdown {
  border: 1px solid #d9d9d9;
  padding-top: 0px !important;
  .custom-select-dropdown {
    padding-left: 15px;
    .ant-divider {
      width: 90%;
      min-width: 90%;
      margin: 12px 0;
      border-top: 1px solid #d9d9d9;
    }
    .ant-checkbox-group-item {
      margin: 5px 0px;
      color: #231f20;
      font-size: 13px;
      font-weight: 400;
      display: flex;
    }
    .ant-check {
      border: 1px solid #606060;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fff;
      border-color: #606060;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      position: absolute;
      display: table;
      border: 2px solid #606060;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: " ";
    }
  }
}

.department-emails-table,
.activity-type-table {
  .ant-form-item-explain {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    // background: #fff;
    // color: #231f20;
    // svg {
    //   fill: #eb078e !important;
    // }
    // .activities-attach {
    //   fill: #231f20 !important;
    // }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #fff;
  }
}

.switch-parent-div {
  color: #aaaaaa;
  font-family: "Open Sans", Arial;
  font-weight: 600;
  font-size: 13px;
  .off {
    color: rgb(226, 15, 0);
  }
  .on {
    color: #3f9d2f;
  }
  .ant-switch-checked {
    background-color: #e2e2e2;
    .ant-switch-handle::before {
      background-color: #3f9d2f !important;
    }
  }
  .ant-switch {
    width: 50px;
    height: 20px;
    .ant-switch-handle {
      width: 15px;
      height: 15px;
    }
    .ant-switch-handle::before {
      background-color: rgb(226, 15, 0);
    }
  }
}

.ant-select-item:hover {
  background-color: #eb078e;
  color: #fff;
}

.custom-blue-btn,
.custom-blue-btn:hover,
.custom-blue-btn:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #f72fa1;
  border-color: #f72fa1;
  background: #fff;
  border: 1px solid rgba(41, 170, 225, 1);
  // height: 40px;
  color: rgba(41, 170, 225, 1);
  svg {
    fill: rgba(41, 170, 225, 1);
  }
  .anticon + span {
    margin-left: 2px;
  }
}

.activities-popconfirm {
  .ant-popover-buttons {
    text-align: center;
    direction: rtl;
  }
}

.activities-timePicker {
  border-radius: 0px !important;
  input {
    padding-left: 0px !important;
  }
  .ant-picker-input::after {
    background-image: none;
    content: none;
  }
  background: #fff !important;
  border-color: #d9d9d9;
  cursor: not-allowed;
  box-shadow: 0 0 0 0px rgb(235 7 142 / 20%);
}

.activities-time-picker {
  .ant-picker-time-panel {
    width: 112px;
  }
  .ant-picker:not(.ant-picker-range) .ant-picker-input input {
    color: #171c26;
    padding-left: 0px;
    text-align: center;
  }
}

.attach-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activities-attach-popover {
  .ant-popover-inner-content {
    max-width: 260px;
    border: 1px solid #eb078e;
    .close-icon-div {
      display: flex;
      justify-content: end;
      margin-bottom: 20px;
    }
    .rotate-icon {
      transform-origin: 9px 30px;
      transform: rotate(320deg);
    }
    .name-span {
      text-decoration: underline;
      word-break: break-all;
      cursor: pointer;
    }
    .del-icon {
      cursor: pointer;
    }
    .add-attach-btn > .anticon + span {
      margin-left: 4px;
    }
    .add-attach-btn {
      margin: 5px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 113px;
      border: 1px solid #eb078e;
      // height: 40px;
      height: 26px;
      padding: 4px 6px;
      color: #eb078e;
      svg {
        fill: #eb078e;
      }
    }
  }
}

.add-comment-popover {
  .ant-popover-content {
    border: 1px solid #eb078e;
    border-radius: 16px;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px #eb078e;
    }
    .ant-popover-inner {
      border-radius: 16px;
      .title {
        padding-left: 11px;
      }
      .ant-popover-inner-content {
        .invalid-text {
          background-color: rgb(247, 247, 247);
        }
        .valid-text {
          background-color: #fff;
        }
        .valid-text-btn {
          background-color: #eb078e;
          color: white;
        }
        .invalid-text-btn {
          background-color: #fff;
          color: #eb078e;
        }
        textarea[readonly] {
          border-color: transparent;
        }
      }
    }
  }
  .ant-popover-title {
    padding: 12px 12px 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid transparent;
    .title {
      line-height: 16px;
      color: rgba(35, 31, 32, 1);
      font-family: "Open Sans", Arial;
      font-weight: 600;
      font-size: 13px;
    }
    svg {
      font-size: 14px;
    }
  }
  .ant-popover-inner-content {
    .ant-btn {
      line-height: 1;
      color: #f72fa1;
      border-color: #f72fa1;
      background: #fff;
      width: 80px;
      height: 24px;
    }
  }
}

.recorded-time-popover {
  .ant-popover-content {
    border: 1px solid #eb078e;
    .ant-popover-arrow-content {
      box-shadow: 1px 1px 1px #eb078e;
    }
  }
  .ant-popover-title {
    padding: 12px 12px 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid transparent;
  }
}

.activities-time-picker {
  .ant-picker-input input {
    color: #171c26;
    padding-left: 12px !important;
  }
  .ant-picker-input::after {
    content: none;
  }
}

.prod-due-date .ant-picker-input input {
  padding-left: 0px !important;
}

.ant-input::placeholder,
input::placeholder,
.ant-select-selection-placeholder {
  color: #231f2080 !important; // red
  font-size: 13px !important;
  font-weight: normal !important;
  font-family: "Open Sans";
  // line-height: 1 !important;
  // text-transform:capitalize;
}

.border-less-input {
  border: none;
  padding: 4px 0px;
  text-align: center;
  background-color: transparent;
  color: #231f2080;
}

.multi-select-custom {
  .rc-virtual-list-holder {
    .ant-select-item {
      padding: 8px 2px 5px 10px;
    }
  }

  .rc-virtual-list-holder-inner > :first-child[label="Select All"] {
    border-bottom: 1px solid grey;
    padding: 12px 2px 12px 10px;
    .ant-select-item-option-content {
      .select-all-div {
        display: flex;
        justify-content: space-between;
        div {
          font-size: 13px;
          color: #231f20;
        }
      }
      display: flex;
      justify-content: space-between;

      .cross-icon svg {
        fill: #231f20 !important;
        width: 16px;
        height: 16px;
      }
    }
  }
  .rc-virtual-list-holder-inner > :nth-child(2) {
    // padding: 12px 2px 12px 10px;
    padding: 8px 2px 5px 10px;
    .ant-checkbox-wrapper {
      .ant-checkbox + span {
        padding-right: 2px;
      }
    }
  }

  .done-btn {
    border: 1px solid rgba(235, 7, 142, 1);
    color: rgba(235, 7, 142, 1);
    border-radius: 2px;
    width: 80px;
    line-height: 1;
    height: 20px;
    padding: 0px 14px;
    margin: 10px auto;
  }
}

.closee {
  right: 10px;
  top: 29px;
  position: absolute;
  z-index: 1;
}

.ant-layout-content.layout-content.site-layout-background.custom-textarea {
  position: relative !important;
}

.select-dropdown-custom {
  .ant-select-item {
    .ant-select-item-option {
      color: rgb(35, 31, 32);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
    .ant-select-item-option-content {
      font-size: 13px;
    }
    .ant-select-item-option-selected {
      color: white;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #eb078e;
    font-weight: 400;
  }
}

.production-table-popover-custom {
  padding: 0px;
  .ant-popover-inner-content {
    display: none;
  }
  .ant-popover-title {
    background-color: black;
    color: white;
    text-align: center;
    min-width: 100px;
    border: 1px solid white;
  }
  .ant-popover-arrow-content {
    box-shadow: none;
    display: none;
    &:before {
      content: none;
    }
  }
}

@primary-color: #eb078e;