body {
  margin: 0;
  font-family: "Open Sans", Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Open Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

@primary-color: #eb078e;