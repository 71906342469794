.page-top-div {
  .add-activity-btn,
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    width: 137px;
    border: 1px solid #eb078e;
    // height: 40px;
    background-color: white;
    color: #eb078e;
    svg {
      fill: #eb078e;
    }
  }
}

@primary-color: #eb078e;