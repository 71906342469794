.notification-modal {
  background-color: transparent !important;
  box-shadow: none;
  margin: 0;
  top: 130px;
  left: 30px;
  .ant-modal-header {
    padding: 8px 16px;
    background-color: #f8f8f8;
    .ant-modal-title {
      text-transform: uppercase;
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-size: 12px;
    }
  }
  .ant-modal-body {
    padding: 0;
    max-height: 50vh;
    min-height: 40vh;
    border-radius: 3px;
    overflow-y: overlay;
    &::before {
      content: "";
      display: block;
      width: 90%;
      margin: 0em auto 0;
      border-bottom: 2px solid #eb078e;
    }
  }
  .ant-modal-body::-webkit-scrollbar {
    display: none;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 3px 3px 0px 0px !important;
    // border-bottom: 1px solid #eb078e;
    border-bottom: none;
  }

  .ant-modal-content {
    border: 1px solid #eb078e;
    box-shadow: 1px 1px 3px rgb(235 7 142 / 50%);
    margin-right: 2px;
    margin-left: 2px;
    background-color: #f8f8f8;
  }
  .ant-list-bordered {
    border: none;
  }
  .ant-modal-close-x {
    font-size: 14px;
  }
  .ant-modal-close {
    right: -5px;
    top: -10px;
  }
  .anticon-close {
    color: grey !important;
  }

  .notifications-list {
    .ant-list-item {
      display: block;
    }
    .time-div {
      color: #717171;
      font-size: 11px;
      font-weight: 400;
    }
    .message-body {
      color: #383838;
      font-size: 11px;
      font-weight: 400;
    }
    .unread-listitem {
      cursor: pointer;
    }
    .unread-message {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 575px) {
  .notification-modal {
    right: 0;
    margin-left: auto;
  }
}

@primary-color: #eb078e;