// Range Picker Starts

.ant-picker-header-view {
  display: grid;
}

.ant-picker {
  padding: 1px 8px 0px;
}

// .ant-picker-input::before {
//   display: block;
//   content: " ";
//   background-size: 28px 28px;
//   height: 23px;
//   width: 23px;
// }

.ant-picker-header button {
  line-height: 30px;
}

.ant-picker-header {
  padding: 0px 16px;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  .ant-picker-header-view {
    .ant-picker-month-btn {
      font-weight: bold;
    }
    .ant-picker-year-btn {
      color: grey !important;
    }
  }
}

.ant-picker-dropdown-range {
  width: 335px;
}
.ant-picker-header-next-btn {
  position: absolute;
  left: 300px;
}

.ant-picker-active-bar {
  display: none;
}
.ant-picker-cell .ant-picker-cell-inner {
  padding: 10px;
  height: 35px;
  line-height: normal;
  min-width: 35px;
}

.ant-picker.ant-picker-range {
  width: 100%;
  // min-width: 200px;
  // max-width: 200px;
  margin-bottom: 1px;
}

.ant-picker-panel-focused {
  border-color: #e9edf5;
}

.range-picker-time {
  .ant-picker-input:first-child {
    padding-left: 25px;
    max-width: 181px;
  }
}

.range-picker-time {
  .ant-picker-input:not(:first-child) {
    max-width: 155px;
  }
}

.range-date-picker {
  .ant-picker-input:first-child {
    padding-left: 25px;
    max-width: 113px;
  }
}

.range-date-picker {
  .ant-picker-input:not(:first-child) {
    max-width: 88px;
  }
}

.ant-picker-range-separator {
  padding: 0 5px;
}

.ant-picker-input input {
  color: #eb078e;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.ant-picker-cell::before {
  left: -4px;
  height: 35px;
}

.ant-picker-input::before {
  display: none;
}
.custom-range-picker {
  .ant-picker-cell-inner:not(.ant-picker-cell-in-view.ant-picker-cell-selected
      .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start
      .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end
      .ant-picker-cell-inner) {
    &:hover {
      background-color: #ccddff;
    }
  }

  .ant-picker-range-wrapper {
    min-width: 100% !important;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: #ccddff;
  }

  .ant-picker-header-view {
    margin-left: -19px;
  }

  .ant-picker-content th {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 18px;
    color: #687182;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  .ant-picker-content td {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background-color: white !important;
    letter-spacing: 0.02em;
  }
  .ant-picker-content td .ant-picker-cell-in-view {
    color: #464f60;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #ccddff;
  }

  .ant-picker-cell {
    padding: 0.4px 0;
  }

  .ant-picker-header {
    border-bottom: none;
    padding: 0 20px;
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 0 12px 4px;
  }
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  height: 35px;
}

.range-picker-with-filter {
  .ant-picker {
    border-radius: 0 6px 6px 0;

    &:hover {
      border-radius: 0 6px 6px 0;
    }

    @media screen and (max-width: 576px) {
      border-radius: 6px;

      &:hover {
        border-radius: 6px;
      }
    }
  }
  .ant-picker:focus,
  .ant-picker-focused {
    border-radius: 0 6px 6px 0;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(70, 79, 96, 0.32),
      0px 4px 10px -2px rgba(89, 96, 120, 0.16);

    @media screen and (max-width: 576px) {
      border-radius: 6px;
    }
  }
}

.range-picker-button-menu {
  .ant-dropdown-menu-item {
    &:hover {
      background-color: #ebf0fa;
      border-radius: 4px;
    }
  }
}

// Range Picker Ends

// Time Range picker to and from
.time-picker-from,
.time-picker-to {
  color: #464f60;
}

.time-picker-from {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    border-right: 1px solid #d5dbe5;
    top: 24px;
    right: 9px;
    height: 25px;
  }
}

.ant-picker-panel-container {
  margin-left: 0 !important;
}

.ant-picker-cell-disabled::before {
  background-color: #ffffff;
}

.time-picker {
  .ant-picker-time-panel {
    width: 137px;
  }

  .ant-picker-ok {
    margin-top: 5px;
  }
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background-color: #ffffff;
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner:hover {
    background-color: #ccddff;
  }
  .ant-picker-time-panel-column {
    width: 45px;
  }
}

.ant-picker-time-panel-column:hover {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// .ant-picker {
//     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
//       0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 2px 5px rgba(89, 96, 120, 0.1);
//     border: none;
//     height: 32px;
//     line-height: 32px;
//     padding: 0 10px;
//     border-radius: 6px;
//     background-color: #ffffff;
//   }

.ant-picker-arrow .anticon:not(.ant-picker-suffix) {
  pointer-events: none;
}
.ant-picker-picker-icon-placeholder {
  color: #464f60 !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.ant-picker:not(.ant-picker-customize-input) .ant-picker-picker-error {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.ant-picker-single:not(.ant-picker-customize-input) .ant-picker-picker-error {
  padding: 1px 11px !important;
}

//   .ant-picker:not(.ant-picker-disabled):hover {
//     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
//       0px 0px 0px 1px rgba(70, 79, 96, 0.32),
//       0px 4px 10px -2px rgba(89, 96, 120, 0.16);
//     border-radius: 6px;
//     border: none;
//   }
//   .ant-picker:focus,
//   .ant-picker-focused {
//     box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
//       0px 0px 0px 1px rgba(70, 79, 96, 0.32),
//       0px 0px 0px 4px rgba(34, 100, 230, 0.4), 0px 2px 5px rgba(89, 96, 120, 0.1);
//     border-radius: 6px;
//   }

//   .ant-picker-focused:not(.ant-picker-disabled).ant-picker:not(.ant-picker-customize-input)
//     .ant-picker-pickeror {
//     box-shadow: none;
//     border: none;
//   }

.ant-form-item-has-error {
  .ant-picker:not(.ant-picker-disabled) {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
      0px 0px 0px 1px rgba(209, 41, 61, 0.4);
    border: none;
    border-radius: 6px;
  }

  // .ant-picker:focus,
  // .ant-picker-focused:not(.ant-picker-disabled) {
  //   box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
  //     0px 0px 0px 1px rgba(209, 41, 61, 0.4) !important;

  //   border-radius: 6px !important;
  // }

  .ant-picker-focused:not(.ant-picker-disabled).ant-picker:not(.ant-picker-customize-input)
    .ant-picker-pickeror {
    box-shadow: none;
    border: none;
  }
}

.ant-picker:not(.ant-picker-range) .ant-picker-input input {
  &:extend(.s2);
  color: #171c26;
  padding-left: 28px;
}

.ant-picker-input::after {
  display: block;
  content: " ";
  background-image: url("../assets/date.svg");
  background-size: 20px 14px;
  height: 14px;
  width: 22px;
}

.remove-calendar-icon {
  .ant-picker-input::after {
    background-image: none;
  }
}

.custom-date-picker {
  .ant-picker-cell-inner {
    padding: 10px 10px;
    line-height: normal;
    height: auto;
    &:hover {
      background-color: #ebf0fa;
    }
  }

  .ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
    padding: 10px 10px;
    background-color: #2264e5;
    line-height: normal;
    height: auto;
    &:hover {
      background-color: #2264e5;
    }
  }

  .ant-picker-content th {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12.5px;
    line-height: 18px;
    color: #687182;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  .ant-picker-content td {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background-color: white !important;
    letter-spacing: 0.02em;
  }
  .ant-picker-content td .ant-picker-cell-in-view {
    color: #464f60;
  }

  .ant-picker-cell {
    padding: 2px 0;
  }

  .ant-picker-header {
    border-bottom: none;
    padding: 0 20px;
  }

  .ant-picker-date-panel .ant-picker-body {
    padding: 0 12px 4px;
  }
}

.ant-picker-month-btn,
.ant-picker-year-btn {
  &:extend(.s2);
  color: #222834 !important;
}

.ant-picker-header-super-prev-btn {
  display: none;
}
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  width: 335px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 310px;
}

//set date icon
.ant-picker:not(.ant-picker-range) .ant-picker-suffix {
  position: absolute;
  top: 2px;
}

.ant-picker-range .ant-picker-suffix {
  position: absolute;
  bottom: 5px;
}

@primary-color: #eb078e;