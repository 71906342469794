.heading-parent-div {
  .photo-col {
    @media screen and (max-width: 991px) {
      padding-left: 0px !important;
    }
    .img-parent-div {
      @media screen and (max-width: 991px) {
        width: 90px !important;
      }
    }
  }
}

.heading-parent-div {
  .empty-div {
    margin-top: 60px;
  }
  svg {
    cursor: pointer;
  }
  .ant-input {
    padding: 0px 11px;
  }
  .is-edit {
    .ant-input,
    .ant-input-number-disabled {
      border: none;
    }
    .ant-input-disabled,
    .ant-input-number-disabled {
      cursor: text;
      background-color: #fff;
    }
    .ant-select-selector {
      border: none;
    }
    .ant-select-disabled .ant-select-arrow {
      cursor: text;
      display: none;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input,
    .ant-input-number-disabled .ant-input-number-input {
      background: #fff;
      cursor: text;
    }

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after,
    .ant-checkbox-disabled .ant-checkbox-inner {
      border-color: rgba(56, 56, 56, 1) !important;
      background-color: #fff;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      input,
    .ant-checkbox-disabled .ant-checkbox-input,
    .ant-checkbox-disabled,
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: text;
    }
    .ant-input[disabled],
    .ant-input-number-disabled,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-checkbox-disabled + span {
      color: rgba(56, 56, 56, 1);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-size: 13px;
      cursor: text;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 24px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number-input {
    height: 24px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 22px;
  }
  .back-btn,
  .add-department-btn,
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eb078e;
    background: #fff;
    // height: 40px;
    color: #eb078e;
    svg {
      fill: #eb078e;
    }
    .anticon + span {
      margin-left: 2px;
    }
  }
  .back-btn {
    margin-right: 40px;
    width: 100px;
  }
  .add-department-btn {
    width: 114px;
  }
  .user-manager-parent-div {
    margin-top: 20px;
    box-shadow: 1px 1px 4px 1px rgba(56, 56, 56, 0.4) !important;
    background-color: rgb(255, 255, 255);
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    width: 100%;
    .heading-col {
      border-bottom: 1px solid #eb078e;
      padding: 10px 25px 10px 12px;
      margin-bottom: 25px;
      .heading-span {
        line-height: 17px;
        letter-spacing: 0px;
        color: #eb078e;
        font-family: "Open Sans", Arial;
        font-weight: 700;
        text-transform: uppercase;
      }
      svg {
        width: 16px;
        height: 16px;
        fill: #eb078e;
      }
    }
    label {
      color: rgba(56, 56, 56, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      padding-left: 10px;
      text-transform: uppercase;
    }
    .settings-section {
      margin-bottom: 100px;
    }
    .section-body {
      .heading-text {
        text-transform: uppercase;
        color: rgb(56, 56, 56);
        font-family: "Open Sans", Arial;
        font-weight: 700;
        font-style: normal;
        font-size: 11px;
        margin-bottom: 6px;
      }
    }
  }
  .user-details-section {
    .password-div {
      color: rgba(41, 170, 225, 1);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      margin-top: 3px;
      cursor: pointer;
      span {
        padding-left: 10px;
      }
    }
    .send-details-parent-div {
      display: flex;
      justify-content: center;
      .disabled-onclick {
        pointer-events: none;
        opacity: 0.7;
      }
      .send-details-div {
        margin-top: 80px;
        border: 1px solid rgba(235, 7, 142, 1);
        border-radius: 2px;
        width: 127px;
        height: 44px;
        text-align: center;
        color: rgba(235, 7, 142, 1);
        font-family: "Open Sans", Arial;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        cursor: pointer;
        span {
          display: block;
        }
      }
    }
    .user-detail-form {
      .ant-input-number {
        width: 100%;
      }
      .ant-form-vertical .ant-form-item-label,
      .ant-col-24.ant-form-item-label,
      .ant-col-xl-24.ant-form-item-label {
        padding: 0px;
      }
      .ant-form-item-label > label {
        height: 8px;
      }
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
    .permissions-div {
      text-transform: capitalize;
    }
    .photo-col {
      text-align: center;
      color: rgba(56, 56, 56, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      padding-left: 10px;
      text-transform: uppercase;
      .edit-image {
        border: 1px solid rgb(206, 212, 218);
      }
      .img-parent-div {
        border-radius: 3px;
        width: 100px;
        height: 88px;
        margin: auto;
        border-radius: 8px;
        position: relative;
        .img-nested-div {
          width: 82px;
          height: 82px;
          margin: auto;
          img {
            border-radius: 70px;
            vertical-align: text-top;
            object-fit: cover;
          }
        }
        .edit-icon-div {
          position: absolute;
          bottom: 0px;
          right: 5px;
          color: red;
        }
      }
      .text-div {
        max-width: 133px;
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0px;
        margin: 5px auto;
        line-height: 12px;
        color: #717171;
      }
    }
  }
}

.create-password-modal {
  .ant-modal-content {
    border: 2px solid rgba(41, 170, 225, 1);
    .ant-modal-close-icon svg {
      fill: rgba(41, 170, 225, 1);
    }
  }
  .ant-modal-header {
    border-bottom-color: rgba(41, 170, 225, 1);
    padding: 12px 8px 6px 8px;
    margin: 0 16px;
    .ant-modal-title {
      margin-top: 20px;
      color: rgba(41, 170, 225, 1);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      border-bottom: none;
      text-transform: uppercase;
    }
  }
  .ant-modal-body {
    padding: 16px 24px 32px 24px;
    label {
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 700;
      font-style: normal;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 12px;
    }
    .text-div {
      color: rgb(56, 56, 56);
      font-family: "Open Sans", Arial;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      text-align: left;
      line-height: 13px;
    }
    .ant-input-affix-wrapper {
      height: 24px;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: rgba(41, 170, 225, 1);
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px rgb(41 170 225 / 20%);
    }
    .confirm-btn {
      height: 30px;
      width: 127px;
      margin: 0 auto;
    }
  }
}

.pin-modal {
  .ant-modal-body label {
    padding-left: 0px;
  }
  input {
    width: 27px !important;
    margin-right: 12px !important;
  }
}

@primary-color: #eb078e;