.layout-content {
  @media screen and (max-width: 991px) {
    padding: 0px 15px !important;
  }
}

.ant-layout-sider {
  background: #171717;
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item
    .ant-menu-item-icon
    + span {
    display: none;
  }
}
.most-parent-layout {
  height: 100vh;
  .site-layout {
    background-color: #f8f8f8;
    display: flex;
  }
}

.layout-content {
  padding: 0px 25px;
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow-y: auto;
}

.main-sidebar-collapsed {
  min-height: 100vh;
  background-color: #171717;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
  text-align: center;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: sticky;
}

.main-sidebar {
  min-height: 100vh;
  background-color: #171717;
  width: 260px;
  min-width: 260px;
  max-width: 260px;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: sticky;
}

.sidebar-pin-icon {
  position: absolute;
  top: 5px;
  right: 6px;
  display: flex;
  z-index: 10;
  cursor: pointer;
  svg {
    height: 12px;
    width: 12px;
    fill: #e2e2e2;
  }
}

.sidebar-child-div {
  display: flex;
  // padding:  "10px 30px",
  //   borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  cursor: pointer;
  // margin: `5px 0px`,
  height: 80px;
  padding: 20px;
}

.nav-collapsed-img-div {
  // display: "table",
  // verticalAlign: "-0.5em",
  height: 30px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.nav-uncollapsed-img-div {
  width: 196px;
  height: 29px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}

.menu-item-for-top-border {
  position: relative;
  width: 100%;
}

.menu-item-for-top-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  border-top: 1px solid rgba(226, 226, 226, 11%);
  width: 250px;
}

.menu-item-for-top-border::after {
  content: "";
  position: absolute;
  top: 0;
  left: 5px;
  border-bottom: 1px solid rgba(226, 226, 226, 11%);
  width: 250px;
}

.sidebar-notification-menu-item {
  background-color: #171717;
  font-size: 15px;
  padding-left: 20px;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  svg {
    font-size: 27;
    color: white;
    fill: #e2e2e2;
  }
}

.notification-icon {
  font-size: 19px;
  color: white;
  fill: #e2e2e2;
}

.sidebar-custom > li:nth-last-child(3) {
  border-bottom: 1px solid hsla(0, 0%, 88.6%, 0.11);
}

.ant-menu-submenu-popup .subitems-custom,
.ant-menu-item-selected {
  display: flex;
  align-items: center;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item {
  display: flex;
  align-items: center;
}

.sidebar-custom,
.ant-menu-submenu-popup {
  background-color: #171717;
  font-size: 16px;
  max-width: 260px;
  min-width: 70px;
  padding-left: 0;
  .sidebar-profile-icon {
    .ant-avatar {
      font-size: 27px;
      display: flex;
      align-items: center;
      height: 30px;
      width: 30px;
    }
  }
  .ant-menu-item-selected {
    .ant-menu-item-icon,
    .anticon {
      color: #eb078e;
      fill: #eb078e;
    }
    .ant-menu-item-icon svg {
      fill: #eb078e;
    }
  }

  .settings-title-span {
    margin-left: 10px;
    color: #ffffff;
    font-family: "Open Sans";
  }

  .ant-menu-submenu {
    .ant-menu-sub svg {
      margin-left: 30px !important;
    }
  }

  .ant-menu-item:hover,
  .ant-menu-submenu:hover {
    background-color: #171717;
    color: #eb078e;
    div,
    span {
      .settings-title-span,
      svg {
        fill: #eb078e;
        color: #eb078e;
      }
    }
    .profile-username-div {
      line-height: 24px;
      p {
        margin-bottom: 0px;
      }
      p,
      span {
        background-color: #171717 !important;
        color: #eb078e;
      }
    }
  }

  .subitems-custom:hover {
    svg {
      fill: #eb078e;
      color: #eb078e;
    }
  }

  .ant-menu-item {
    background-color: #171717;
    font-size: 15px;
    padding-left: 20px !important;
    height: 60px;
    font-family: "Open Sans";
    display: flex;
    justify-content: start;
    align-items: center;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }

    .ant-menu-submenu {
      background-color: #171717;
      font-size: 15px;
      height: 60px;
      font-family: "Open Sans";
      display: flex;
      justify-content: start;
      align-items: center;
      color: #ffffff;
      svg {
        fill: #ffffff;
        width: 27px;
        height: 27px;
        font-size: 27px;
      }
    }
  }

  .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 15px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    // color: #ffffff;
    svg {
      fill: #ffffff;
      width: 27px;
      height: 27px;
      font-size: 27px;
    }
  }
  .subitems-custom {
    // border-top: 1px solid rgba(226, 226, 226, 11%);
    background-color: #171717;
    font-size: 15px;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    // color: #e2e2e2;
  }

  .ant-menu-submenu {
    border-bottom: 1px solid rgba(226, 226, 226, 11%);

    .ant-menu-submenu-title {
      font-size: 15px;
    }
    svg {
      fill: #ffffff;
    }
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
    margin-top: 0px;

    .ant-menu-submenu {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  .ant-menu-submenu .ant-menu-submenu-title {
    margin-bottom: 0px;
    margin-top: 0px;
    // padding-left: 0px !important;
    background-color: #171717;
  }
  .profile-avatar {
    width: 27px;
    height: 27px;
    font-size: 27px;
    display: flex;
    align-items: center;
  }
  .profile-svg-icon {
    width: 27px;
    height: 27px;
    font-size: 27px;
    fill: #e2e2e2;
  }
  .profile-menu-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .profile-username-div {
      line-height: 24px;
      span {
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: "Open Sans", Arial;
        font-weight: 300;
        font-size: 11px;
        text-align: left;
      }
      p {
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
        font-family: "Open Sans", Arial;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }
  .ant-badge-count {
    min-width: 18px;
    height: 17px;
    padding: 0 5px;
    font-size: 12px;
    line-height: normal;
    margin-right: 5px;
    margin-top: 2px;
  }
  .notification-icon {
    font-size: 19px;
    svg {
      fill: #ffffff !important;
    }
  }
  .notification-icon:hover {
    font-size: 19px;
    svg {
      fill: #eb078e !important;
    }
  }
  .dot-icon {
    font-size: 27px;
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
  }
  .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 20px !important;
  }
  .subitems-custom {
    background-color: #171717;
    display: flex;
    align-items: flex-start;
  }

  .ant-menu-item-selected {
    background-color: #171717 !important;
    .ant-menu-item-icon + span,
    .anticon + span {
      background-color: #171717 !important;
      color: #eb078e;
    }
    .profile-username-div {
      line-height: 24px;
      p {
        margin-bottom: 0px;
      }
      p,
      span {
        background-color: #171717 !important;
        color: #eb078e;
      }
    }
  }
  .logout-menu-item {
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: all 0.2s;
    background-color: transparent;
    line-height: 21;
    svg {
      font-size: 21px;
      width: 21px;
      height: 21px;
    }
    .ant-spin {
      margin-left: 20px;
    }
  }
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0px;
  margin-bottom: 0px;
}

@primary-color: #eb078e;