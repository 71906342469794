.production-manager-parent-row {
  .heading-col {
    padding: 2px 25px 8px 12px;
  }
  .add-user-btn {
    border: 1px solid #eb078e;
    width: 100px;
    // height: 40px;
    color: #eb078e;
    svg {
      fill: #eb078e;
    }
  }
}

@primary-color: #eb078e;